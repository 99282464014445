<template>
  <div>
    <div class="prose prose-md md:prose-lg">
      <h1>Health Savings Account (HSA)</h1>
      <!-- <h3>
        The 2024 limits for contributions are $4,150 for individuals and $8,300
        for families.
      </h3> -->
      <router-link class="text-xl" to="/support/articles/18310279162131"
        >See HSA Limits</router-link
      >

      <p>
        A Health Savings Account (HSA) is a tax-exempt trust or custodial
        account established exclusively for the purpose of paying qualified
        medical expenses for beneficiaries covered under a qualified High
        Deductible Health Plan (HDHP). With an HSA, you can pay for current and
        future eligible medical, dental, and vision expenses. The funds can be
        withheld through payroll deduction, and therefore employees do not pay
        federal, state, or social security taxes. The employer also saves the
        matching FICA taxes.
      </p>
      <p>In order to open an HSA, you:</p>
      <ul>
        <li>
          Must be covered by a qualifying High Deductible Health Plan (HDHP)
        </li>
        <li>
          Must not be covered by any other health plan that is not an HDHP
        </li>
        <li>Must not be enrolled in Medicare</li>
        <li>
          Must not be claimed as a dependent on another persons tax return
        </li>
      </ul>
      <p>
        An HDHP costs less than traditional health care plans due to the higher
        deductible, so the money saved on premiums can be added to your HSA.
      </p>
    </div>
    <InfoDropdown title="Simple example of how pre-tax works" :headerLvl="2">
      <p>
        Pre-tax dollars mean you get access to the funds before social security,
        federal and state tax, etc. Say you work 10 hours at $10 ($100) and you
        wanted to set aside $25 for eligible expenses. Instead of getting taxed
        on the full $100, you would only get taxed on the $75 since the $25 was
        taken out before!
      </p>
      <p>
        Average taxes in the United States are about 20%, if you were taxed on
        the $100 that totals $20 of taxes. If you were taxed solely on the $75,
        that totals only $15 of taxes. The yearly maximum contribution amount
        for an HSA is $4,150 for an individual or $8,300 for a family. If you
        had a family HSA and made $50,000 annually- you would only be taxed
        $8,580 compared to the full $10,000.
      </p>
      <p>
        If you did not set funds aside, you would pay an extra $1,420 of taxes
        by waiting to pay for your share of medical expenses after-tax.
      </p>
    </InfoDropdown>

    <InfoDropdown title="Accessing HSA Funds/Investments" :headerLvl="2">
      <p>
        You own and control the funds in your HSA. The money you contribute to
        your HSA is federally tax-deductible, whether or not you itemize
        deductions. Your funds can be conveniently accessed by a debit card to
        pay for medical expenses.
      </p>
      <img class="w-auto max-w-full" src="@/assets/card.png" alt="Card Image" />
      <ul>
        <li>It conveniently helps the participant with cash flow</li>
        <li>The card pays directly to the provider at the point of sale</li>
        <li>It adds a convenience to accessing participant funds</li>
      </ul>
    </InfoDropdown>

    <InfoDropdown title="HSA Banking" :headerLvl="2">
      <InfoDropdown title="WealthCare Saver" :headerLvl="3">
        <p>
          <em
            >“WealthCare Saver is a leading custodian of health savings accounts
            (HSAs) with long-standing experience in the market. We love helping
            people save money on healthcare expenses and work behind the scenes
            powering health benefit accounts for over 300 administrators to
            support millions of consumers in getting the most from their
            healthcare dollars.</em
          >
        </p>
        <p>
          <em>
            We are relentless in our commitment to helping our members navigate
            the complex world of healthcare and passionate about disrupting the
            status quo. WealthCare provides the SMART HSA, which offers
            easy-to-use tools that takes the guesswork out of spending and
            saving decisions. Our best-in-class concierge team allows our
            members to speak with experts and receive guidance on saving,
            spending, and investments."
          </em>
        </p>
      </InfoDropdown>

      <InfoDropdown title="UMB HSA" :headerLvl="3">
        <p>
          <em
            >“UMB Healthcare Services, a division of UMB Financial Corporation,
            is a leading provider of health savings accounts and healthcare
            spending account cards with more than $1 billion in HSA assets. For
            the sixth straight year, UMB was ranked as one of America’s Best
            Banks by Forbes and SNL Financial. For over 100 years, UMB has made
            a commitment to principled and sound practices, we simply do not
            chase short-term earnings or growth at the expense of our future.
          </em>
        </p>

        <p>
          <em
            >As a national leader in HSA Custodial Services, we offer fully
            integrated, fully customizable packages, as well as turn-key
            options. Our nationwide network of sales representatives work
            closely with our partners to meet your unique needs, creating
            entirely customized options to keep you on the leading edge of your
            industry.”</em
          >
        </p>
      </InfoDropdown>

      <InfoDropdown title="Disclosure Documents" :headerLvl="3">
        <h4>WealthCare Saver</h4>
        <ul>
          <li>
            <a
              href="https://www.wealthcaresaver.com/disclosures/wcsp"
              target="_blank"
              rel="external"
              >Health Savings Account (HSA) Terms and Conditions</a
            >
          </li>
          <!-- <li>
            <a
              href="https://www.wealthcaresaver.com/docs/Consent_to_Electronic_Communications.pdf"
              target="_blank"
              rel="external"
              >Consent to Electronic Communications</a
            >
          </li>
          <li>
            <a
              href="https://www.wealthcaresaver.com/docs/Health_Savings_Account_Program_Custodial_%20Agreement.pdf"
              target="_blank"
              rel="external"
              >Health Savings Account Program Custodial Agreement</a
            >
          </li>
          <li>
            <a
              href="https://www.wealthcaresaver.com/docs/P_Health_Savings_Account_Fee_Schedule.pdf"
              target="_blank"
              rel="external"
              >Health Savings Account Fee Schedule</a
            >
          </li>
          <li>
            <a
              href="https://www.wealthcaresaver.com/docs/P_Health_Savings_Account_Interest_Rate_Disclosure.pdf"
              target="_blank"
              rel="external"
              >Health Savings Account (HSA) Interest Rate Schedules</a
            >
          </li>
          <li>
            <a
              href="https://www.wealthcaresaver.com/docs/WealthCare_Saver_Privacy_Policy.pdf"
              target="_blank"
              rel="external"
              >Privacy Policy</a
            >
          </li>
          <li>
            <a
              href="https://www.wealthcaresaver.com/docs/WealthCare_Saver_High-Yield_HSA_Supplement.pdf"
              target="_blank"
              rel="external"
              >WealthCare Saver High-Yield HSA Supplement</a
            >
          </li> -->
        </ul>
        <h4>UMB</h4>
        <ul>
          <li>
            <a
              href="https://content.umb.com/file?uuid=ef335d65-12cf-4730-9219-fcd0cb510b93/"
              target="_blank"
              rel="external"
              >ESIGN Disclosure and Consent</a
            >
          </li>
          <li>
            <a
              href="https://content.umb.com/file?uuid=59fdd15c-7c38-4db6-99fd-67ca8ea6a7c5/"
              target="_blank"
              rel="external"
              >UMB Bank HSA Custodial Agreement</a
            >
          </li>
          <li>
            <a
              href="https://content.umb.com/file?uuid=5dab5471-80e8-4f6d-9f45-b7fa469a3c7e/"
              target="_blank"
              rel="external"
              >UMB Bank HSA Deposit Account Terms and Conditions, Regulatory
              Disclosure and Fee Schedule</a
            >
          </li>
          <li>
            <a
              href="https://content.umb.com/file?uuid=d4f40b55-a771-40a9-a123-542fb7b912cd/"
              target="_blank"
              rel="external"
              >UMB Healthcare Services Privacy Notice</a
            >
          </li>
          <li>
            <a
              href="https://content.umb.com/file.pdf?uuid=f163a76d-cb77-420e-96c9-ddd40abaad94/"
              target="_blank"
              rel="external"
              >UMB Investments Terms and Conditions</a
            >
          </li>
        </ul>
      </InfoDropdown>

      <table class="w-full mb-4 text-center table-fixed border-spacing-0">
        <tr class="category header">
          <th class="p-2 text-white rounded-t bg-neutral-900" colspan="2">
            Contributions
          </th>
        </tr>
        <tr class="text-white bg-neutral-900">
          <th class="p-2">WealthCare Saver</th>
          <th class="p-2">UMB</th>
        </tr>
        <tr>
          <td
            class="px-2 pt-2 font-bold text-left border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            Employer Contribution Process to Funds HSAs
          </td>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">
            Yes - fuly integrated with RMR
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            Yes - fully integrated with RMR
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td
            class="px-2 pt-2 font-bold text-left border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            Participant Online & Mobile Apps
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td
            class="p-2 border-0 border-b border-l border-solid rounded-bl border-neutral"
          >
            Yes
          </td>
          <td
            class="p-2 border-0 border-b border-r border-solid rounded-br border-neutral"
          >
            Yes
          </td>
        </tr>
      </table>

      <table class="w-full mb-4 text-center table-fixed border-spacing-0">
        <tr class="category header">
          <th class="p-2 text-white rounded-t bg-neutral-900" colspan="2">
            Distributions
          </th>
        </tr>
        <tr class="text-white bg-neutral-900">
          <th class="p-2">WealthCare Saver</th>
          <th class="p-2">UMB</th>
        </tr>
        <tr>
          <td
            class="px-2 pt-2 font-bold text-left border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            Benefits Debit Card
          </td>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">Yes</td>
          <td class="p-2 border-0 border-r border-solid border-neutral">Yes</td>
        </tr>
        <tr class="bg-neutral-light">
          <td
            class="px-2 pt-2 font-bold text-left border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            Checkbooks
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td
            class="p-2 border-0 border-b border-l border-solid rounded-bl border-neutral"
          >
            Yes - Free
          </td>
          <td
            class="p-2 border-0 border-b border-r border-solid rounded-br border-neutral"
          >
            No checks. Electronic transactions only.
          </td>
        </tr>
      </table>

      <table class="w-full mb-4 text-center table-fixed border-spacing-0">
        <tr>
          <th class="p-2 text-white rounded-t bg-neutral-900" colspan="2">
            Investments
          </th>
        </tr>
        <tr class="text-white bg-neutral-900">
          <th class="p-2">WealthCare Saver</th>
          <th class="p-2">UMB</th>
        </tr>
        <tr>
          <td
            class="px-2 pt-2 font-bold text-left border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            Investments
          </td>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">
            Yes - Self-directed
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            Yes - Self-directed
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td
            class="px-2 pt-2 font-bold text-left border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            Single Sign-on
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td class="p-2 border-0 border-l border-solid border-neutral">
            Single Sign-on
          </td>
          <td class="p-2 border-0 border-r border-solid border-neutral">
            Single Sign-on
          </td>
        </tr>
        <tr>
          <td
            class="px-2 pt-2 font-bold text-left border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            Transfer to Investments
          </td>
        </tr>
        <tr>
          <td
            class="p-2 border-0 border-b border-l border-solid rounded-bl border-neutral"
          >
            Participants can transfer funds to investments at any time if
            balance is over $1,000
          </td>
          <td
            class="p-2 border-0 border-b border-r border-solid rounded-br border-neutral"
          >
            Participants can transfer funds to investments at any time if
            balance is over $1,000
          </td>
        </tr>
      </table>

      <table class="w-full mb-4 text-center table-fixed border-spacing-0">
        <tr>
          <th class="p-2 text-white rounded-t bg-neutral-900" colspan="2">
            Statements/Reporting
          </th>
        </tr>
        <tr class="text-white bg-neutral-900">
          <th class="p-2">WealthCare Saver</th>
          <th class="p-2">UMB</th>
        </tr>
        <tr>
          <td
            class="px-2 pt-2 font-bold text-left border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            IRS Forms 5498 and 1099 SA
          </td>
        </tr>
        <tr>
          <td class="p-2 border-0 border-l border-solid border-neutral">Yes</td>
          <td class="p-2 border-0 border-r border-solid border-neutral">Yes</td>
        </tr>
        <tr class="bg-neutral-light">
          <td
            class="px-2 pt-2 font-bold text-left border-0 border-l border-r border-solid border-neutral"
            colspan="2"
          >
            Monthly Online Statements With Images
          </td>
        </tr>
        <tr class="bg-neutral-light">
          <td
            class="p-2 border-0 border-b border-l border-solid rounded-bl border-neutral"
          >
            Yes - Free
          </td>
          <td
            class="p-2 border-0 border-b border-r border-solid rounded-br border-neutral"
          >
            Yes - Free no images
          </td>
        </tr>
      </table>
    </InfoDropdown>

    <InfoDropdown title="Important features of the plan" :headerLvl="2">
      <p>
        If you use the funds for expenses other than health care-related
        expenses, you must pay income tax plus a 20% penalty. Federal limits
        dictate how much can be contributed to an HSA each year. It also
        provides a $1,000 catch-up provision for individuals over age 55 (not
        enrolled in Medicare). After reaching age 65, the funds may also be used
        for non-medical related expenses. You will only have to pay income tax
        on the withdrawals at that point.
      </p>
      <p>
        If you begin an HSA with an employer and you leave the employer, your
        funds are not lost and can move along with you. The difference is that
        you become responsible for the monthly fees to keep the account open and
        a new card/account will be created for you independent of the former
        employer.
      </p>
    </InfoDropdown>
  </div>
</template>

<script>
import InfoDropdown from "@/components/ui/InfoDropdown.vue";

export default {
  components: {
    InfoDropdown: InfoDropdown
  }
};
</script>
